






















import { Component, Vue } from 'vue-property-decorator'
import { Message } from 'element-ui'
import { LoginByAccount, LoginByWxCode } from '@/api'
import router, { loadRoute } from '@/router'
import { saveUser } from '@/store/userStore'
import { saveLoginName,getLoginName } from '@/store/loginName'
import { getWxMpCode, saveWxMpCode } from '@/store/wxMpCode'
import Conf from '@/config/config'

@Component({
  components: {},
})
export default class Login extends Vue {
  private param = {
    // username: 'dev@163.com',
    // password: '123456',
    username: '',
    password: ''
  }
  private rules = {
    username: [
      {
        required: true,
        message: '请输入用户名',
        trigger: 'blur',
      },
    ],
    password: [{ required: true, message: '请输入密码', trigger: 'blur' }],
  }

  public async created() {
    // 获取微信token
    let code = router.currentRoute.params.code
    if(!code) {
      code = router.currentRoute.query.code as string
    }
    if(code){
      console.log("识别到code:"+code)
      // 保存到缓存
      saveWxMpCode(code)
      // 执行自动通过微信code登录
      const data = await LoginByWxCode({wxMpCode: code,wxAppid:Conf.WX_APP_ID})
      saveUser(data)
      
      // 加载路由
      loadRoute()
      // 哪里来回哪里去
      let f = router.currentRoute.redirectedFrom
      if(!f){
        f = router.currentRoute.meta?.from
      }
      if(f == '/h5/seting'){
        f = '/h5/drug'
      }
      if(f){
        router.push(f);
      }else{
        router.push('/')
      }
    } else{
      this.param.username = getLoginName()
    }
  }

  public submitForm() {
    const login = this.$refs.login as HTMLFormElement
    login.validate(async (valid: boolean) => {
      if (valid) {
        
        // 判断是否存在code
        const code = getWxMpCode() 
        const param: any = {
          account: this.param.username,
          password: this.param.password,
          wxMpCode: code,
          wxAppid: Conf.WX_APP_ID,
        }
        
        const data = await LoginByAccount(param)
        if (data == null) {
          return false
        }
        saveUser(data)
        saveLoginName(param.account)
        Message.success('登录成功')
        loadRoute()

        let f = router.currentRoute.redirectedFrom
        if(!f){
          f = router.currentRoute.meta?.from
        }
        if(f == '/h5/seting'){
          f = '/h5/drug'
        }
        if(f){
          router.push(f);
        }else{
          router.push('/')
        }
      } else {
        Message.error('登录失败')
        return false
      }
    })
  }
}
