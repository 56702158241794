import router from "@/router"

// 用户缓存
export const saveLoginName = (userName: string) =>{
  // 随机输入1/2
  localStorage.setItem("loginName",userName)
}

export const getLoginName = () => {
  const userName = localStorage.getItem("loginName")
  if(userName == null){
    return ""
  }
  return userName
}

