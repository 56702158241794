





















































import { Component, Vue } from 'vue-property-decorator'
import router from '@/router'
import store from '@/store'
import menu from '@/router/menu'
import { MessageBox } from 'element-ui'

@Component
export default class Sidebar extends Vue {
  private items = menu

  public created() {
    // 可以动态变更菜单，只需要动items即可
  }

  private get route() {
    return router.currentRoute
  }

  private get onRoutes() {
    return this.route.path
  }
  private get collapse() {
    return store.state.collapse
  }
  private loginout(){
    console.log("退出 登录")
    MessageBox.confirm('是否退出登录？', '提示', {
      type: 'warning',
    }).then(async () => {
      localStorage.removeItem('userInfo')
      router.push('/login')
    })
  }
}
